<template lang="pug">
article.route.home
    .content
        section.section
                .section-container
                    transition(name="quick-fade")
                        .text
                            h1.h1.headline 404
                            p.lead {{ $t("pagenotfound.lead") }}
                            router-link.button(to='/') {{ $t("pagenotfound.home") }}
</template>
<script>
export default {
    name: 'PageNotFound',
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped lang="scss">
@import '@/scss/_utilities';

.section-container {
    background-color: rgba(0, 0, 0, 0.4);;
}
.text {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.headline {
    font: 200 2.5rem/1.3 'Montserrat', 'Lora', 'Arial', sans-serif;
    text-transform: uppercase;
    text-align: center;

    @include at(laptop) {
        font-size: 4.5rem;
    }

    @include at(laptopLarge) {
        font-size: 7.5rem;
    }
}

.lead {
    font: 400 1.2rem/1.3 'Cabin', 'Arial', sans-serif;
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 32px;

    @include at(tablet) {
        font-size: 1.5rem;
    }
}
</style>
